import React from "react";
import "./style.css";
import arrow from "../../images/rt-arrow.svg"

const Card = (props) => {
    return (
            <a className="card-link text-decoration-none text-dark newPage" href={props.link} style={{cursor:'pointer'}} target="blank" >
                <div className={`img-card  mx-auto p-3 ${ props.color }`}>
                      <div className="d-flex flex-row justify-content-between align-items-center" >  
                        <img className="cardimg" src={props.logo} alt=" oss Logo"/>
                        {props.status==='1live' ? <span className="badge card-status badge-danger">Live</span>: null}
                        {props.status==='2upcoming' ? <span className="badge card-status badge-warning">Upcoming</span>: null}
                        {props.status==='3past' ? <span className="badge card-status badge-success">Past</span>: null}</div>
                        <br/>
                        <h4 className="card-heading">{props.name}</h4>
                        <p className="pt-3 cardpara text">{props.para}</p>
                        
                        <br/>
                       
                        <p className="bottom-text mt-3" >{props.more}</p>
                </div>
            </a>
    );
}


const ProjectCard = (props) => {
    return (
        <div className="img-card p-3">
            <a href={props.link} style={{textDecoration: 'none', cursor:'pointer'}}>
                <div className="icon-box">
                    <img className="cardimg" src={props.logo} alt=" oss Logo"/>
                </div>
                <h4 className="pt-3" style={{color: "#151E3F"}}>{props.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={arrow} alt=""/></h4>
                <p className="cardpara text" style={{color: "#151E3F"}}>{props.para}</p>
                <p className="text" style={{color: "#151E3F"}}><b>{props.stack}</b></p>
            </a>
        </div>
    );
}

const eventCard = (props) => {
    return (
        <div className="eventCard">
            <div className="upperContainer">
                <div className="icon">
                    <img src={props.icon} alt=" oss event-icon"/>
                </div>
            </div>
            <div className="middleContainer">
                <div className="eventHeading">
                    <h1><span className="eventName">{props.eventName}</span><span className="headingArrow">→</span></h1>
                </div>
                <div className="eventInformation">
                    <p>{props.eventInfo}</p>
                </div>
            </div>
            <div className="lowerContainer">
                <div className="regDates">
                    <p>Register before {props.lastDate}</p>
                </div>
            </div>
        </div>
    )
}

const AchievementCard = (props) =>{
    return(
        <div className="achieve-card" >
            <a className="card-link text-decoration-none text-dark" href={props.link} style={{cursor:'pointer'}}>
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img className="achieveImage" src={props.achieImg} alt=" oss cards image" />
                <div className="achieve-card-body">
                    <div className="achieve-content">
                    <h4 className="achieve-card-title">{props.achieTitle}</h4>
                    <p className="achieve-card-text text">{props.achiePara}</p>
                    </div>
                    <div className="achieve-awards">
                        <div className="achieve-award-button align-items-center achieve-position"><i className="fas fa-award"/> {props.position}</div>
                        <div className="achieve-award-button align-items-center achieve-prize">{props.prize}</div>
                    </div>
                </div>
            </a>
      </div>
    )
}

const CoolProjectsCard = (props) =>{
    return(
        <div className="achieve-card project-card-width" >
            <div className="card-link text-decoration-none text-dark"  >
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img className="projectImage" src={props.achieImg} alt=" oss cards image" />
                <div className="achieve-card-body">
                    <div className="achieve-content">
                    <h4 className="achieve-card-title">{props.achieTitle}</h4>
                    <p className="achieve-card-text text">{props.achiePara}</p>
                    </div>
                    <div className="project-awards align-items-center">
                        <a className="coolproject-position project-card" href={props.position} style={{cursor:'pointer'}}><i className="fas fa-link p-2"/>Visit Site</a>
                        <a className="coolproject-position project-card mr-2" href={props.prize} style={{cursor:'pointer'}}><i className="fab fa-github p-2"/>GitHub</a>
                    </div>
                </div>
            </div>
      </div>
    )
}

const MemberCard = (props) =>{
    return(
        <div className="memberCard "  >
            <div className="memberImg p-2 d-flex justify-content-center mt-3" >
                <img src={props.img} alt=""/>
            </div>
            <div className="memberContent">
            <div className="memberName d-flex justify-content-center mt-2 text-align-center">
                <h5 className="font-weight-bold p-0 m-0"> <p className="p-0 m-0"  >{props.name}</p> </h5>
            </div>
            <div className="memberDesg d-flex justify-content-center  text-align-center">
                <p>{props.desgn}</p>
                <p>{props.year}</p>
            </div>
            </div>
            <div className="memberLink d-flex justify-content-center align-items-center  text-align-center">
                <a href={props.github} target="_blank" rel="noopener noreferrer" className="link m-2  d-flex justify-content-center" ><i className="fab fa-github d-flex align-items-center"></i></a>
                <a href={props.linkedin} target="_blank"  rel="noopener noreferrer" className="link m-2  d-flex justify-content-center"> <i  className="fab fa-linkedin-in d-flex align-items-center"></i></a>
                <a href={props.insta} target="_blank" rel="noopener noreferrer" className="link m-2  d-flex justify-content-center"><i  className="fab fa-instagram d-flex align-items-center"></i></a>
            </div>

        </div>
        
    )
}




export default Card;

export {eventCard , AchievementCard, ProjectCard,MemberCard ,CoolProjectsCard};
