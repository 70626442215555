import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import "./style.css";
import {ProjectCard} from '../../cards/index';
import Sugar from "../../../utils/preloader/index";

const myServerBaseURL = "/api/v1/event";

const WebinarPage = (Props) => {

    const [Data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const loadData = useCallback(() => {
        axios.get(`${myServerBaseURL}`).then((response) => {
            let typeclass = response.data.event.filter(even => even.typeOfEvent === "seminar")

            setData(typeclass);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        Loading ? <Sugar/> :
        <div className="webinarPage">
            <div className="container-fluid mt-3">
                <h2 className="heading text-center p-3 col-md-12">Feel free to <span
                            className="AchieveRed"> Join </span> us &#9996;. We conducts <span
                            className="AchieveRed">Amazing </span> Webinars/Seminars <span className="AchieveGreen"> to help you. </span>!
                        </h2>
                <div className="row">
                    {Data.map((webinardata,index) => {
                        return (
                            <div className="col-md-4 mb-4 pb-2"  key={index}>
                                <ProjectCard
                                    className="col-md-3"
                                    link={webinardata.link}
                                    logo={webinardata.logo}
                                    name={webinardata.title}
                                    para={webinardata.description}
                                    stack={webinardata.stack}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default WebinarPage;
