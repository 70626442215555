import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import { motion } from "framer-motion";
import achieveImage from '../../images/acheiveImage.svg';
import { AchievementCard } from './../../pages/cards/index';
import "./style.css";
import Sugar from "../../utils/preloader/index";

const myServerBaseURL = "/api/v1/achievement";
//  const [Loading, setLoading] = useState(true);
const Achievements = (props) =>{
    const [Data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const loadData = useCallback(() => {
        axios.get(`${myServerBaseURL}`).then((response) => {
            setData(response.data.achievement);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        Loading ? <Sugar/>:
        <motion.div initial= {{opacity:0, scale: 0.8 }} animate={{opacity:1, scale: 1, duration:0.5}} transition= {{type: "Tween"}} className="Achievements">
            <div className="container-fluid col-12">
                <div className="col-md-12 row justify-content-between align-items-center">
                    <div className="col-md-6 mt-5">
                        <h1 className="AchieveHeading ">
                            Achievements to {" "}
                            <span className="highlightblue">inspire juniors</span>{" "}
                            & {" "} <span className="highlightred">make</span>{" "} the alumni{" "}
                            <span className="highlightred">proud.</span>
                        </h1>
                        <p className=" mt-3 content para">
                            Every year members of OSS club partcipate in numerous 
                            <span className="achieve-bold"> hackathons and win fellowships</span> and many 
                            <span className="achieve-bold"> prestigious titles. </span>
                            Take a look to get the inspiration to keep the fire burning. We have our footprints in more than 
                            <span className="achieve-bold"> 150+ hackathons, GSoC, Linux Foundation </span> and many more.
                        </p>
                    </div>


                    <div className="col-md-4 mt-5 order-first order-md-last">
                        <img className="col-md-12" src={achieveImage} alt ="oss achievements svg"/>
                    </div>

                </div>


                    <div className="row achieve-start mt-5 ">
                        {Data.map((achievedata,index) => {
                            return (
                               
                                <div className="achieve-page-card mt-4" key={index}>
                                    <AchievementCard  
                                        achieImg={achievedata.logo}
                                        achieTitle={achievedata.title}
                                        achiePara={achievedata.description}
                                        position={achievedata.position}
                                        prize={achievedata.prize}
                                        link={achievedata.link}
                                    />
                                </div>
                            )
                        })}
                    </div>
            </div>
         <br/>
        </motion.div>
    )

};


export default Achievements;
