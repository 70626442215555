import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import "./style.css";

class BottomNavbar extends Component {
    
    render() {
        

        const navlinks = [
            { key: "HomePage", icon: "fas fa-home", name: "HomePage", url: "/" },
            { key: "Events HitList", icon: "far fa-star", name: "Events HitList", url: "/Events"},
            { key: "Cool Projects", icon: "fas fa-tools", name: "Cool Projects", url: "/Projects"},
            { key: "Achievements", icon: "fas fa-medal", name: "Achievements", url: "/Achievements"},
            { key: "Club Members", icon: "fas fa-users", name: "Club Members", url: "/ClubMembers"},
        ];
          let NavbarLink;
        return (
            <div className="bottom-navbar d-flex f-row justify-content-around align-items-center">
               
                    {navlinks.map((link,index) => {
                    return (
                        
                        <li className="bottom-nav-list" onClick= {NavbarLink} key={index}>
                        <NavLink to={link.url} style={{cursor: 'pointer'}} exact className=" " >
                          <div className="d-flex f-row  justify-content-center align-items-center flex-wrap my-2">
                         
                          <i className={`p-2 ${link.icon}`} />
                            <div className="bottom-navbar-icon-name">{link.name}</div>
                          </div>
                        </NavLink>
                      </li>
                    );
                    })}
                    
            </div>
        );
    }
}

export default BottomNavbar;
