import React, {useState, useCallback } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import Card from './../../pages/cards/index';
import fbLogo from './../../images/facebook.svg';
import linkedinLogo from './../../images/linkedin.svg';
import instagramLogo from './../../images/instagram.svg';
import twitterLogo from './../../images/twitter.svg';
import githubLogo from './../../images/github.svg';
import slackLogo from './../../images/icons8-slack-new.svg';
import "./style.css";

const ServerBaseURL = "/api/v1/contact";

const Contact = () => {
  const [newDataName, setNewDataName] = useState("");
  const [newDataEmail, setNewDataEmail] = useState("");
  const [newDataMessage, setNewDataMessage] = useState("");
  const [isLoading, setNewloading] = useState(false);

  const onCreateNewData = useCallback(
    (newDataName,newDataEmail,newMessage) => {
      setNewloading(!isLoading);
      axios
        .post(ServerBaseURL, {
          name: newDataName,
          email: newDataEmail,
          msg: newMessage
        }).then((response) => {
          setNewDataName("");
      setNewDataMessage("");
      setNewDataEmail("");
        alert("Mail Sent! We will connect with you shortly.");
        })
        .catch((error) => {
          console.log(error);
          alert("Cant send Email");
        });
    },
    [isLoading]
  );

  const onNewDataNameChange = useCallback((event) => {
    setNewDataName(event.target.value);
  }, []);

  const onNewDataMessageChange = useCallback((event) => {
    setNewDataMessage(event.target.value);
  }, []);

  const onNewDataEmailChange = useCallback((event) => {
    setNewDataEmail(event.target.value);
  }, []);

  const onAddData = useCallback(
    (event) => {
      event.preventDefault();
      onCreateNewData(newDataName,newDataEmail,newDataMessage);
    },
    [onCreateNewData, newDataName,newDataEmail,newDataMessage]
  );



  return (
        <motion.div initial= {{opacity:0, scale: 0.8 }} animate={{opacity:1, scale: 1}} transition= {{duration: 0.2}} className="Contact">
          <div className="col-md-12 row">
            <div className="col-md-5 p-md-5 p-sm-3 contact-form" >
              <div className="contact-form-text px-4 pt-2 ">
                <h1 className="my-3 highlight contactHeading">Get <span className="highlightblue">In Touch</span></h1>
                <p className="ml-2 contact-page-content "><span className="highlightred">Not literally though.</span><span role="img" aria-label="Emoji">😷</span></p>
                <p className="ml-2 contact-page-content ">We take our commitment to students seriously.
                                    We’re always here, If you have any query feel free to
                                    mail us anytime.</p>
              </div>
              <form className="form-horizontal p-4" onSubmit={onAddData}>
                <div className="form-group">
                  <textarea className="form-control" rows="6" id="queries" placeholder="Write your queries here."  value={newDataMessage} onChange={onNewDataMessageChange} required/>
                </div>
                <div className="form-group">
                  <input type="email" className="form-control" id="email" placeholder="Email"  value={newDataEmail} onChange={onNewDataEmailChange} required/>
                </div>
                <div className="col-12 d-flex p-0 flex-row justify-content-between">
                  <div className="form-group">
                    <input type="text" className="form-control" id="name" placeholder="Name" value={newDataName} onChange={onNewDataNameChange} required/>
                  </div>
                  <button type="submit" className="align-items-center contact-form-button btn-danger" disabled={isLoading}>Send <i className="far fa-paper-plane"/>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-7 p-md-5 p-sm-2 d-flex flex-row flex-wrap">
                <div className="contact-social-text pt-2 ">
                  <h1 className="my-3 highlight contactHeading">Not a <span className="highlightblue"> mail person </span>?</h1>
                  <p className="ml-2 contact-page-content">No worries you can also reach us out on different <span className="highlightred">social media platforms</span>. We are always online, consider us Hyper-active.<br></br>If that’s a thing ...</p>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-around">
                  <div className="formCard my-2">
                    <Card
                      link="https://www.facebook.com/groups/ossclubait"
                      logo={fbLogo}
                      name="Facebook"
                      para="Join Facebook group to stay updated with events’ news."
                    />
                  </div>
                  <div className="formCard my-2">
                    <Card
                      link="https://www.instagram.com/oss_club_ait/"
                      logo={instagramLogo}
                      name="Instagram"
                      para="We share crazy memes here. But you can DM queries too."
                    />
                  </div>
                  <div className="formCard my-2">
                    <Card
                      link="https://twitter.com/innerve24hrs"
                      logo={twitterLogo}
                      name="Twitter"
                      para="Staying away from politics.Just here for fun."
                    />
                  </div>
                  <div className="formCard my-2">
                    <Card
                      link="https://www.linkedin.com/company/open-source-software-club/"
                      logo={linkedinLogo}
                      name="LinkedIn"
                      para="What a better place to have a chat professionally."
                    />
                  </div>
                  <div className="formCard my-2">
                    <Card
                      link="https://github.com/aitoss"
                      logo={githubLogo}
                      name="GitHub"
                      para="This one is not for queries. Check our code-cool stuff."
                    />
                  </div>
                  <div className="formCard my-2">
                    <Card
                      link="https://join.slack.com/t/ossdev-workspace/shared_invite/zt-d6kpend1-yA6SEoHDvGUrUt2U~lOvOA"
                      logo={slackLogo}
                      name="Slack"
                      para="We share useful information here. We are more than 200 developers."
                    />
                  </div>
                </div>
            </div>
          </div>
          <br/>
          <div className="footer-contact">
              <p>© AIT OSS 2021 . Made With <span role="img" aria-labelledby="heart">❤️</span></p>
          </div>
          <br/>
        </motion.div>
  );
};

export default Contact;
