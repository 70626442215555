import React,{Component} from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar  from './constants/navbar/index';
import HomePage  from "./components/homePage/index";
import Landing  from "./pages/landingPage/landingpage";
import Events  from "./components/events/index";
import Achievements from "./components/achievements/index";
import Projects from "./components/projects/index";
import ContactTeam from "./components/contact/index";
import ClubMembers from "./components/clubMembers/index";
import EventPages from './pages/eventHitlist/eventPage/index';
import ClassPages from './pages/eventHitlist/classPage/index';
import WebinarPages from './pages/eventHitlist/webinarPage/index';
import BottomNavbar from "./constants/bottomNavbar/index.jsx";
import UpperNavbar from "./constants/upperNavbar/index";
class App extends Component {

  state = {
    loading: false
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading:false
      })
    }, 5500) 

  }
  render(){
  return (
    <React.Fragment>
      {
        this.state.loading ? <Landing/> :
          <Router>
             <div className="app-nav">
                <div className="app-bottomNav-display"><UpperNavbar/></div>
                <div className="app-bottomNav-display"><BottomNavbar /></div>
                <div className="app-Nav-display"><Navbar /></div>
              </div>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/Events" component={Events} />
              <Route exact path="/Projects" component={Projects} />
              <Route exact path="/Achievements" component={Achievements} />
              <Route exact path="/ClubMembers" component={ClubMembers} />
              <Route exact path="/Team" component={ContactTeam} />
              <Route exact path="/EventsPage" component={EventPages} />
              <Route exact path="/ClassPage" component={ClassPages} />
              <Route exact path="/WebinarPage" component={WebinarPages} />
            </Switch>
          </Router>

      }
    </React.Fragment>
  );
  }
}

export default App;
