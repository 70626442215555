import React, { Component } from "react";
import {motion} from "framer-motion";
import github from "../../images/python.svg";
import react from "../../images/source-code.svg";
import telegram from "../../images/source-code.svg";
import ubuntu from "../../images/ubuntu.svg";
import html from "../../images/html.svg";
import docker from "../../images/icons8-docker.svg";
import linux from "../../images/icons8-linux.svg";
import opensource from "../../images/icons8-open-source.svg";
import gmail from "../../images/icons8-gmail.svg";
import windows from "../../images/icons8-windows-xp.svg";
import hacking from "../../images/icons8-hacking.svg";
import canva2 from "../../images/icons8-canva.svg";
import drive from "../../images/icons8-google-drive.svg";
import gitlab from "../../images/icons8-gitlab.svg";

import "./style.css";

class Landing extends Component {
  render() {
    return (
      <motion.div initial= {{opacity:0, scale: 0.8 }} animate={{opacity:1, scale: 1}} transition= {{duration: 0.2}} className="HomePage">
        <div className="wrapperback">
       
<div>
  <div className="starsec"></div>
  <div className="starthird"></div>
  <div className="starfourth"></div>
  <div className="starfifth"></div>
</div>

<div className="lamp__wrap">
  <div className="lamp">
    <div className="cable"></div>
    <div className="cover"></div>
    <div className="in-cover">
      <div className="bulb"></div>
    </div>
    <div className="light"></div>
  </div>
</div>

          <div className="particle elem1 anim-delay1">
            <img src={html} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem2 anim-delay5">
            <img src={react} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem3 anim-delay3">
            <img src={telegram} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem4 anim-delay8">
            <img src={html} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem5 anim-delay8">
            <img src={gmail} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem6 anim-delay9">
            <img src={github} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem8 anim-delay2">
            <img src={telegram}  alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem9 anim-delay2">
            <img src={windows} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem11 anim-delay4">
            <img src={hacking} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem13 anim-delay10">
            <img src={ubuntu} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem16 anim-delay6">
            <img src={github} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem17 anim-delay6">
            <img src={canva2} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem18 anim-delay9">
            <img src={ubuntu} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem19 anim-delay3">
            <img src={html} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem20 anim-delay3">
            <img src={drive} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem16 anim-delay12">
            <img src={gitlab} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem15 anim-delay16">
            <img src={telegram} alt="oss-landing-page-icons"/>
          </div>
          <div className="particle elem10 anim-delay10">
            <img src={docker} alt="oss-landing-page-icons"/>
          </div>

          <div className="particle elem7 anim-delay10">
            <img src={linux}  alt="oss-landing-page-icons"/>
          </div> <div className="particle elem12 anim-delay10">
            <img src={opensource} alt="oss-landing-page-icons"/>
          </div>
          <section className="wrapper">
            <div className="container">
              <div id="scene" className="scene" data-hover-only="false">
                <div className="circle" data-depth="1.2"/>
                <div className="circle c1"/>
                <div className="circle c2"/>
                <div className="one" data-depth="0.9">
                  <div className="content">
                    <span className="piece"/>
                    <span className="piece"/>
                    <span className="piece"/>
                  </div>
                </div>

                <div className="two" data-depth="0.60">
                  <div className="content">
                    <span className="piece"/>
                    <span className="piece"/>
                    <span className="piece"/>
                  </div>
                </div>

                <div className="three" data-depth="0.40">
                  <div className="content">
                    <span className="piece"/>
                    <span className="piece"/>
                    <span className="piece"/>
                  </div>
                </div>

                <p className="p404" data-depth="0.50">
                  OSS
                </p>
                <p className="p404" data-depth="0.10">
                  OSS
                </p>
              </div>

              <div className="text">
                <article>
                  <p>Embracing OSS at AIT.</p>
                </article>
              </div>
            </div>
          </section>
        </div>
      </motion.div>
    );
  }
}

export default Landing;
