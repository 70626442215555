import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import { motion } from "framer-motion";
import coolprojectImg from '../../images/12345.svg';
import "./style.css";
import { CoolProjectsCard } from './../../pages/cards/index';
import Sugar from "../../utils/preloader/index"

const myServerBaseURL = "/api/v1/project";

const Project = () => {

    const [Data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const loadData = useCallback(() => {
        axios.get(`${myServerBaseURL}`).then((response) => {
            setData(response.data.data);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        Loading ? <Sugar/> :
        <motion.div initial= {{opacity:0, scale: 0.8 }} animate={{opacity:1, scale: 1, duration:0.5}} transition= {{type: "Tween"}} className="Projects">
        <div className="container-fluid col-12">    
            
            <div className="col-md-12 row justify-content-between align-items-center upperPad mt-3 p-0 ">
                <div className="col-md-6 mt-5">
                <h2 className="projectHeading float-left">
                    We {" "} 
                        <span className="highlightblue ">create projects</span>{" "}  to help students  and to{" "} 
                        <span className="highlightred">refine our skills.</span> 
                    </h2>

                    <br/><br/><br/>
                    <p className="project-page-content mt-3 content text-dark">
                    Over the years, OSS Team has <span className="project-page-highlight-content"> developed various projects in order to ease our  lives from saving library fines to keeping code backups.</span> All projects are listed here,feel free to use and appreciate them. Go explore and <span className="project-page-highlight-content">don’t forget to make a pull request.</span> 
                    
                        </p>
                        
                </div>
                <div className="col-md-4 mt-2 order-first order-md-last mb-3 ">
                        <img className="col-md-12" src={coolprojectImg} alt ="oss projects svg"/>
                </div>
            
            </div>
           


            <div className="row achieve-start project-card-deck mt-5 ">
                        {Data.map((projectData,index) => {
                            return (
                                <div className="achieve-page-card  p-0 mt-4" key={index}>
                                    <CoolProjectsCard
                                        achieImg={projectData.image}
                                        achieTitle={projectData.title}
                                        achiePara={projectData.description}
                                        position={projectData.githubLink}
                                        prize={projectData.githubLink}
                                    />
                                </div>
                            )
                        })}
            </div>

           <br/>
         
        </div>
        </motion.div>
    )
};


export default Project;

