import React from "react";
import { motion } from "framer-motion";
import Eveimage from './../../images/eventPageImage.svg';
import "./style.css";
import Eventsimage from '../../images/surface2.svg';
import Classesimage from '../../images/eventpageClassImage.svg';
import Seminarsimage from '../../images/surface3.svg';
import { NavLink } from "react-router-dom";


const Event = () => {
 
    return (
        <motion.div initial= {{ opacity:0,x: 2, y: 2, z :2 }} animate={{ opacity:1, x: 0, y: 0, z :0}} transition= {{duration: 0.4}} className="Events">
            <div className="container-fluid ">
                <div className="col-md-12 row mx-0 justify-content-between align-items-center p-0 mb-5">
                    <div className="col-md-7">
                        <h1 className="EveHeading mt-5">
                            We {" "}
                            <span className="highlightblue">organise</span>{" "}
                            different learning {" "}<br/>
                            <span className="highlightred mbileLeft">sessions, classes </span>
                            & exciting <span className="highlightred">events.</span>
                        </h1>
                        <p className="event-page-content col-md-10 p-0 mt-3">
                            Every year OSS organizes events for introducing students to the world of
                            <span className="event-page-highlight-content"> Linux enviornment, Git workflow, Cloud Technologies, DevOps etc, </span>
                             conducts workshops on 
                            <span className="event-page-highlight-content"> Web development, App development </span>
                             and organizes the grand yearly 
                            <span className="event-page-highlight-content"> Hackathon "InNerve".</span>
                        </p>
                    </div>
                    <div className="col-md-4 mt-4 order-first order-md-last">
                        <img className="col-md-10 col-sm-11 justify-content-center order-first order-md-last" src={Eveimage} alt="oss events svg"/>
                    </div>
                </div>

                <div className="container-fluid mb-5 p-0">
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    {/* <motion.h2 initial={{opacity:0,y:90}} animate={{opacity:1,y:0}} transition={{delay:0.6, duration:0.6,type: "spring", damping: 16, stiffness: 200,}} className="heading text-center p-3 col-md-12">Feel free to <span
                        className="AchieveRed"> join</span> us &#9996;. Everyone is <span
                        className="AchieveRed">Welcome</span> in the <span className="AchieveGreen"> Open Source Community  </span>
                    </motion.h2>
                     */}
                    <div className="mx-auto event-page-card d-flex flex-wrap justify-content-around">
                        <div className="event-card col-md-3">
                            <NavLink className="card-link text-decoration-none text-dark" to="/EventsPage" style={{cursor:'pointer'}} target="_blank">
                            <img src={Eventsimage} className="card-img-top pt-2 cImage" alt="oss event svg"/>
                            <div className="event-card-body mt-3">
                                <h5 className="event-card-title">Organise Events</h5>
                                <p className="event-card-text pb-4">OSS Club organizes numerous events throughout the year for students irrespective of their year and branch with the aim of helping them learn new technologies.
</p>
                                <div className="event-card-small-text"><small className="text-muted">Check all events → </small></div>
                            </div>
                            </NavLink>
                        </div>
                        <div className="event-card col-md-3 ">
                            <NavLink className="card-link text-decoration-none text-dark" to="/ClassPage" style={{cursor:'pointer'}} target="_blank">
                            <img src={Classesimage} className="card-img-top pt-2 cImage" alt="oss class svg"/>
                            <div className="event-card-body mt-3">
                                <h5 className="event-card-title">Classes & sessions</h5>
                                <p className="event-card-text pb-4"> OSS Club conducts regular classes for students of AIT covering many concepts from basics to advanced on topics like Frontend, Backend, Cloud, DevOps etc.
</p>
                                <div className="event-card-small-text"><small className="text-muted">Check upcoming schedule → </small></div> 
                            </div>
                            </NavLink>
                        </div>
                        <div className="event-card col-md-3">
                            <NavLink className="card-link text-decoration-none text-dark" to="/WebinarPage" style={{cursor:'pointer'}} target="_blank">
                            <img src={Seminarsimage} className="card-img-top pt-2 cImage" alt="oss seminar svg"/>
                            <div className="event-card-body mt-3">
                                <h5 className="event-card-title">Seminar/Webinar</h5>
                                <p className="event-card-text pb-4">Coping up with the online classes, OSS Club organizes regular webinars and interactive sessions with domain experts to motivate students.</p>
                                <div className="event-card-small-text"><small className="text-muted">Check all webinars → </small></div>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </motion.div>
    )
};


export default Event;
