import React from "react";
import "./style.css";

const Preloader = (Props) => {

    return(
        <div className="pre">
        <div className="preloader loading">
        <span className="slice"></span>
        <span className="slice"></span>
        <span className="slice"></span>
        <span className="slice"></span>
        <span className="slice"></span>
        <span className="slice"></span>
      </div>
      </div>
    )
}

export default Preloader;
