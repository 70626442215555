import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import "./style.css";
import {ProjectCard} from '../../cards/index';
import Sugar from "../../../utils/preloader/index";

const myServerBaseURL = "/api/v1/event";

const EventsPage = (Props) => {

    const [Data, setData] = useState([]);
    const [Loading,setLoading] = useState(true);
    const loadData = useCallback(() => {
        axios.get(`${myServerBaseURL}`).then((response) => {
            let typeclass = response.data.event.filter(even => even.typeOfEvent === "event")

            setData(typeclass);
            setLoading(false);

        });
    }, []);
    useEffect(() => {
        loadData();
    }, [loadData]);

    return(
        Loading ? <Sugar/>:
        <div className="eventPage">
            <div className="container-fluid mt-3">
                            <h2 className="heading text-center p-3 col-md-12">Feel free to <span
                            className="AchieveRed"> Join </span> us &#9996;. You can <span
                            className="AchieveRed">Teach / Learn</span> in our <span className="AchieveGreen"> Open Source Community  </span>!
                        </h2>
                <div className="row">
                    {Data.map((eventsdata,index) => {
                        return (
                            <div className="col-md-4 mb-4 pb-2" key={index}>
                                <ProjectCard
                                    className="col-md-3"
                                    link={eventsdata.link}
                                    logo={eventsdata.logo}
                                    name={eventsdata.title}
                                    para={eventsdata.description}
                                    stack={eventsdata.stack}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default EventsPage;
