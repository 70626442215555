import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import {motion} from "framer-motion";
import "./style.css";
import Himg from "../../images/homepageImg.svg";
import Himg2 from "../../images/direct-cards.png";
import Himg4 from "../../images/teach-card.png";
import Himg1 from "../../images/laptop-cards.png";
import Himg3 from "../../images/gear-card.png";
import Card from "../../pages/cards/index";
import Sugar from "../../utils/preloader/index";

const myServerBaseURL = "/api/v1/updatesCard";

const HomePage = (props) =>{

    const [Data, setData] = useState([]);
    const [Loading,setLoading] = useState(true);
    const loadData = useCallback(() => {
        axios.get(`${myServerBaseURL}`).then((response) => {
            setData(response.data.data);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        loadData();
    }, [loadData]);



return (
    Loading ? <Sugar/> :
    <motion.div className="Homepage d-flex flex-column" initial= {{opacity:0, scale: 0.8 }} animate={{opacity:1, scale: 1}} transition= {{duration: 0.2}}>
        <div className="container-fluid main d-flex flex-column flex-wrap align-items-center mb-5 hero">
            <div className="col-md-10 col-sm-12  mt-4 ">
                <motion.h1 className="highlight">
                Using 
                    <span className="highlightblue "> Open Source</span>{" "}  to{" "}
                    <span className="highlightred">innovate</span> <br/> amazing{" "}
                    <span className="highlightred">projects</span> for a better world.
                </motion.h1>
                <p className="headerpara col-md-11 mx-auto text-center py-4">
                    AIT Open Source Software Club consist of aspiring students who want to learn and develop things using the Open Source methodology. Our main goal is to spread awareness about the goodness of sharing knowledge via contributing to the Open Source Community and making good use of it in the real time applications.
                </p>
                <div className=" d-flex flex-wrap justify-content-center  mx-auto heroIMG ">
                    <img className="img-fluid mt-4  " src={Himg} alt = "oss svg"/>
                </div>
            </div>
           
        </div>
       
        <div className="container main d-flex flex-column align-items-center text-center home-heading mt-4 mb-5">
            <div>What does {" "}<span className="text-bold">OSS Club</span> do?</div>
            <div className="sub-heading">Here fun Activities never go out of stock !</div>
        </div>
        <div className="container-fluid d-flex flex-wrap mx-auto justify-content-center card-box">
                <div className="col-lg-5 col-xl-3 col-xxl-3 col-md-5 col-sm-6 justify-content-center updateCards"><Card  
                    link="/EventsPage"
                    logo={Himg1}                    
                    name="Organize Events"
                    para="We organise many exciting events for young developers and help them practise their skills for real life applications"
                    more="All events here →"
                /></div>
                <div className="col-lg-5 col-xl-3 col-xxl-3 col-md-5 col-sm-6 justify-content-center updateCards"><Card
                    link="/ClassPage"
                    logo={Himg2}
                    color="pink-text"                    
                    name="Guide Students"
                    para="We organise classes for studens to help them learn and implement new technologies."
                    more="Checkout Classes →"
                /></div>
               <div className="col-lg-5 col-xl-3 col-xxl-3 col-md-5 col-sm-6 justify-content-center updateCards"> <Card
                    link="https://www.youtube.com/c/OpenSourceDevelopersSocietyAIT"
                    logo={Himg3}                    
                    name="Create Awareness"
                    para="We spread awareness about Open Source by organising influential interviews,webinars and seminars"
                    more="Get info→"
                /></div>
                <div className="col-lg-5 col-xl-3 col-xxl-3 col-md-5 col-sm-6 justify-content-center updateCards" ><Card
                    link="https://anubhav.aitoss.club/"
                    logo={Himg4}                    
                    name="Inspire Devlopers"
                    para="OSS makes amazing projects to inspire inquisitive community members."
                    more="Anubhav  →"
                /></div>
        </div>
        <div className="container main d-flex flex-column align-items-center text-center home-heading mt-4 mb-5">
            <div>Recent & Relevant {" "}<span className="text-bold">Activities</span> </div>
            <div className="sub-heading">Check out our most recent works!</div>
        </div>
        <div className=" container-fluid  d-flex flex-wrap flex-row justify-content-center mx-auto card-box ">
            {Data.slice(0,8).map((homedata,index) => {
                        
                        var a=((index-1)%4===0 ? "pink-text" : " " ) 
                        return(
                            <div className="col-lg-5 col-xl-3 col-xxl-3 col-md-5 col-sm-6 justify-content-center updateCards" key={homedata._id}>
                                <Card
                                    link={homedata.link}
                                    logo={homedata.image}
                                    status={homedata.status}
                                    name={homedata.title}
                                    para={homedata.description}
                                    color= {a}
                                    
                                                                     
                                />
                            </div>
                            
                            
                        )
            })}
        </div>

    </motion.div>
)};

export default HomePage;
