import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import { motion } from "framer-motion";
import "./style.css";
import { MemberCard } from './../../pages/cards/index';
import memberImage from "../../images/clubmember.svg";
import Sugar from '../../utils/preloader/index';


const ClubMembers = ()=>{
  let myServerBaseURL = "/api/v1/clubMembers";
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [activeMem,setActive] =useState("-active");
  const [activeAlu,setActiveAlu]=useState("");
  async function onMemberClickHandler() {
    setLoading(true)
    myServerBaseURL="/api/v1/clubMembers"
    await axios.get(`${myServerBaseURL}`).then((response) => {
      setData(response.data.data);
      setLoading(false);
      setActive("-active");
      setActiveAlu("");
    });

  }

  async function onAlumniClickHandler() {
    setLoading(true)
    myServerBaseURL = "/api/v1/clubMembers/alumni"

    await axios.get(`${myServerBaseURL}`).then((response) => {
      setData(response.data.data);
      setLoading(false);
      setActive("");
      setActiveAlu("-active");
    });

  }

  
  const loadData = useCallback(() => {
      axios.get(`${myServerBaseURL}`).then((response) => {
          setData(response.data.data);
          setLoading(false);
      });
  },[myServerBaseURL]);
  useEffect(() => {
      loadData();
  }, [loadData]);

  
    return(
      Loading ? <Sugar/>:
      <motion.div initial= {{opacity:0, scale: 0.8 }} animate={{opacity:1, scale: 1, duration:0.5}} transition= {{type: "Tween"}} className="Members">
          <div className="container-fluid col-md-12 row upperPad align-items-center mt-5">
            <div className="col-md-8 mainHead">
            <h2 className="clubMembersHeading float-left col-9 p-0">
                Meet the {" "} 
                    <span className="highlightblue ">OSS Crew</span>{" "}, the pillars{" "} 
                    <span className="highlightred">holding the club </span>together.{" "}
                </h2>

                <p className=" mt-3 content  text-dark col-9 p-0">
                  More than a community <span className="font-weight-bold">we all are a family</span>, this bond will last forever and we'll always be <span className="font-weight-bold">there for you </span> whenever you'll need our help.
                    </p> 
            </div  >
            <div className="col-md-4 ">
                <img className="col-md-10" src={memberImage} alt="oss members svg" />
            </div>
          </div>
          
          <div className="container-fluid mt-3">
            <div className="memberBtn ml-4 ">
              <button onClick={onMemberClickHandler}  className={`club-member-button ${activeMem}`}>Members</button>
              <button onClick={onAlumniClickHandler}  className={`club-member-button ${activeAlu}`}>Club Alumni</button>
            </div>
            <div className="d-flex col-lg-12 col-md-12  flex-wrap memberSection mt-4 p-0" >
              {Data.map((ClubMembers,index)=>{
                if(ClubMembers.isAlumni){
                  return (
                  
                    <div className="at-column m-3" style={{width:'200px'}} key={index}>
                      <MemberCard 
                      img={ClubMembers.image}
                       name={ClubMembers.name} 
                       year={ClubMembers.year}
                       insta={ClubMembers.facebookLink}
                       linkedin={ClubMembers.linkedInLink}
                       github={ClubMembers.githubLink}/>
                    </div>
                  )
                }
                else{
                  return (
                  
                    <div className="at-column m-3" style={{width:'200px'}} key={index}>
                      <MemberCard 
                      img={ClubMembers.image}
                       name={ClubMembers.name} 
                       desgn={ClubMembers.designation}
                       insta={ClubMembers.facebookLink}
                       linkedin={ClubMembers.linkedInLink}
                       github={ClubMembers.githubLink}/>
                    </div>
                  )
                }
               
              })}
            </div>
          </div>
          <br/>
  </motion.div>
    )
};


export default ClubMembers;
