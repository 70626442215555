import React, { Component } from "react";
import { motion } from "framer-motion";
import NavBarLogo from "../../images/logo.svg";
import { NavLink } from "react-router-dom";

import Backdrop from "./../../utils/backdrop/index";
import "./style.css";

class navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        sideDrawerOpen: false
    };
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}; 
    })
  };

  render() {
    const navlinks = [
      { key: "HomePage", icon: "fas fa-home", name: "HomePage", url: "/" },
      { key: "Events HitList", icon: "far fa-star", name: "Events HitList", url: "/Events"},
      { key: "Cool Projects", icon: "fas fa-tools", name: "Cool Projects", url: "/Projects"},
      { key: "Achievements", icon: "fas fa-medal", name: "Achievements", url: "/Achievements"},
      { key: "Club Members", icon: "fas fa-users", name: "Club Members", url: "/ClubMembers"},
      { key: "Contact Team", icon: "fas fa-envelope", name: "Contact Team",url: "/Team"}
    ];

    let sideDrawer = "Navbar";
    let backDrop = false;
    let NavbarLink;

    if(this.state.sideDrawerOpen){
      sideDrawer = "NavBar open"
      backDrop = true
      NavbarLink = this.drawerToggleClickHandler
    }

    return (
      <div className="SideDrawer">
        {/* <button className="toggle-button" onClick={this.drawerToggleClickHandler}>
          <motion.img whileHover={{ scale: 1.2,transition: { duration: 0.2 }, }} className="burgermenu" src={menu_icon} alt="oss sidebar"/>
        </button> */}
        <div className={sideDrawer}>
          <nav className="menu">
            <div className="menu-header">
            <a href="/">
              <motion.img whileHover={{ scale: 1.03,transition: { duration: 0.2 } }} className="logo" src={NavBarLogo} alt="aitoss logo" />
            </a>
          </div>
          <div className="menu-body mt-3">
              <ul>
                {navlinks.map((link,index) => {
                  return (
                      <li className="nav-list" onClick= {NavbarLink} key={index}>
                        <NavLink to={link.url} style={{cursor: 'pointer'}} exact className="py-1" >
                          <div className="d-flex flex-row cd justify-content-center align-items-center mx-2 p-2">
                         
                          <i className={`p-2 ${link.icon}`} />
                            <div>{link.name}</div>
                          </div>
                        </NavLink>
                      </li>
                  );
                })}
              </ul>
          </div>
          </nav> 
        </div>
        <Backdrop show={backDrop} clicked={this.drawerToggleClickHandler}/>
      </div>
    );
  }
}

export default navbar;

