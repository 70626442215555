import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.svg"; 
import "./style.css";

class UpperNavbar extends Component {
    render() {
        const navlinks = [
            // { key: "HomePage", icon: home_icon, name: "HomePage", url: "/" },
            // { key: "Events HitList", icon: event_icon, name: "Events HitList", url: "/Events"},
            // { key: "Cool Projects", icon: project_icon, name: "Cool Projects", url: "/Projects"},
            // { key: "Achievements", icon: achievements_icon, name: "Achievements", url: "/Achievements"},
            // { key: "Club Members", icon: team_icon, name: "Club Members", url: "/ClubMembers"},
            // { key: "Github Repo", icon: github_icon, name: "Github Repo",url: "https://github.com/aitoss"},
            { key: "Contact Team", icon:"fas fa-envelope fa-2x color-blue", name: "Contact Team",url: "/Team"}
          ];
          let NavbarLink;
        return (
            <div className="upper-navbar py-1 d-flex frow-row align-items-center justify-content-between">
                <a href="/"><div className="upper-left-element d-flex flex-row justify-content-around align-items-center text-bold ">
                    <img src={logo} alt="oss logo"></img>
                    <p>AIT OSS Club</p>
                </div></a>
                <div className="upper-right-element d-flex  flex-row justify-content-around align-items-center py-1 ">
                   <a href="https://github.com/aitoss"> <div className=" align-items-center">
                   <i className="ml-2 fab fa-github fa-2x color-blue" ></i>
                    </div></a>
                    {navlinks.map((link,index) => {
                    return (
                        <div className=" mx-3 py-1" onClick= {NavbarLink} key={index}>
                            <NavLink to={link.url} style={{cursor: 'pointer'}} exact className="px-1" >
                           
                            <i className={`p-2 ${link.icon}`} />
                                
                            
                            </NavLink>
                        </div>
                    );
                    
                    })}
                    
                </div>
                  
            </div>
        );
    }
}

export default UpperNavbar;
